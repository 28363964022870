<template>
    <div>
        <BaseDashboardTable
            :columns="columns"
            :items="sortedTrips"
            :sort="sortState"
            :empty-message="'Keine Fahrten gefunden'"
            scrollContainer="Trips"
            isClickable
            @sort-changed="handleSortChanged"
            @row-click="handleRowClick"
        >
            <template #title>
                <h3>Fahrten</h3>
            </template>

            <!-- Custom column rendering -->
            <template #column-umsatz="{ item }">
                <span :style="{ color: hasTripWarning(item.trip) ? 'red' : 'inherit' }">
                    {{ formatCurrency(item.trip.finalTotalAmount) }}
                </span>
            </template>
        </BaseDashboardTable>
    </div>
</template>

<script>
import { format } from 'date-fns';
import Button from '@/components/widgets/Button';
import { round2d } from '@/lib/helper';
import BaseDashboardTable from '@/components/BaseDashboardTable';

export default {
    name: 'TripsTable',
    components: {
        BaseDashboardTable,
        Button,
    },
    props: {
        trips: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            drivers: JSON.parse(localStorage.getItem('drivers')),
            sortState: {
                column: 'startAt',
                ascending: false,
            },
            columns: [
                { key: 'driver', label: 'Fahrer', width: '15%' },
                { key: 'licenseNumber', label: 'Kennzeichen' },
                { key: 'shiftSequenceNumber', label: 'Schicht Nr' },
                { key: 'sequenceNumber', label: 'Signal Nr' },
                { key: 'startAt', label: 'Abfahrt' },
                { key: 'endAt', label: 'Ankunft' },
                { key: 'totalDistance', label: 'Gesamt KM', align: 'right' },
                { key: 'hiredDistance', label: 'Besetzt KM', align: 'right' },
                { key: 'onCallDistance', label: 'Leer KM', align: 'right' },
                { key: 'umsatz', label: 'Umsatz (€)', align: 'right' },
            ],
        };
    },
    computed: {
        mTrips() {
            return this.trips || [];
        },
        formattedTrips() {
            return this.mTrips.map(trip => this.formatTrip(trip));
        },
        sortedTrips() {
            if (!this.formattedTrips.length) return [];

            const { column, ascending } = this.sortState;

            return [...this.formattedTrips].sort((a, b) => {
                let aVal, bVal;

                // Select the appropriate values for sorting based on column
                if (column === 'startAt') {
                    aVal = a._startAt;
                    bVal = b._startAt;
                } else if (column === 'endAt') {
                    aVal = a._endAt;
                    bVal = b._endAt;
                } else if (column === 'totalDistance') {
                    aVal = a._totalDistance;
                    bVal = b._totalDistance;
                } else if (column === 'hiredDistance') {
                    aVal = a._hiredDistance;
                    bVal = b._hiredDistance;
                } else if (column === 'onCallDistance') {
                    aVal = a._onCallDistance;
                    bVal = b._onCallDistance;
                } else if (column === 'umsatz') {
                    aVal = a._umsatz;
                    bVal = b._umsatz;
                } else if (column === 'driver') {
                    // Use the special driver sort value
                    aVal = a._driver;
                    bVal = b._driver;

                    // Special handling for driver names that are numeric
                    const aIsNumeric = !isNaN(aVal) && aVal !== '';
                    const bIsNumeric = !isNaN(bVal) && bVal !== '';

                    // If both are numeric, compare as numbers
                    if (aIsNumeric && bIsNumeric) {
                        aVal = parseFloat(aVal);
                        bVal = parseFloat(bVal);

                        if (aVal < bVal) return ascending ? -1 : 1;
                        if (aVal > bVal) return ascending ? 1 : -1;
                        return 0;
                    }

                    // If only one is numeric, non-numeric values come first in ascending order
                    if (aIsNumeric && !bIsNumeric) return ascending ? 1 : -1;
                    if (!aIsNumeric && bIsNumeric) return ascending ? -1 : 1;

                    // If both are strings, continue with normal string comparison below
                } else if (column === 'shiftSequenceNumber' || column === 'sequenceNumber') {
                    // Ensure numeric comparison for sequence numbers
                    aVal = parseInt(a[column], 10) || 0;
                    bVal = parseInt(b[column], 10) || 0;
                } else {
                    // For other text fields like licenseNumber
                    aVal = a[column];
                    bVal = b[column];
                }

                // Case insensitive comparison for strings
                if (typeof aVal === 'string' && typeof bVal === 'string') {
                    aVal = aVal.toLowerCase();
                    bVal = bVal.toLowerCase();
                }

                // Handle null/undefined values
                if (aVal === null && bVal === null) return 0;
                if (aVal === null) return ascending ? 1 : -1;
                if (bVal === null) return ascending ? -1 : 1;

                if (aVal === undefined && bVal === undefined) return 0;
                if (aVal === undefined) return ascending ? 1 : -1;
                if (bVal === undefined) return ascending ? -1 : 1;

                // Standard comparison
                if (aVal < bVal) return ascending ? -1 : 1;
                if (aVal > bVal) return ascending ? 1 : -1;

                return 0;
            });
        },
    },
    methods: {
        round2d,
        formatTrip(trip) {
            const driver = this.drivers.find(d => d.id === trip.driverNumber);

            // Create a driver sorting value that's consistent, ensuring it's a string
            const driverSortValue = (
                driver?.name ||
                driver?.value ||
                trip.driverNumber
            )?.toString();
            return {
                driver: driver?.value || trip.driverNumber,
                licenseNumber: trip?.licenseNumber || '',
                shiftSequenceNumber: trip.shiftSequenceNumber,
                sequenceNumber: trip.sequenceNumber,
                startAt: format(new Date(trip.startAt), 'dd.MM.yy HH:mm'),
                endAt: format(new Date(trip.endAt), 'dd.MM.yy HH:mm'),
                totalDistance: round2d(trip.totalDistance / 1000).format(),
                hiredDistance: round2d(trip.hiredDistance / 1000).format(),
                onCallDistance: round2d(trip.onCallDistance / 1000).format(),
                umsatz: round2d(trip.finalTotalAmount / 100).format(),

                // Raw values for sorting
                _driver: driverSortValue, // Add this for consistent driver sorting
                _startAt: new Date(trip.startAt),
                _endAt: new Date(trip.endAt),
                _totalDistance: trip.totalDistance,
                _hiredDistance: trip.hiredDistance,
                _onCallDistance: trip.onCallDistance,
                _umsatz: trip.finalTotalAmount,
                _id: trip.uuid,
                // Store original trip for reference
                trip: trip,
            };
        },
        handleSortChanged(sort) {
            // Map BaseDashboardTable sort to our sort state
            this.sortState = {
                column: sort.column,
                ascending: sort.ascending,
            };
        },
        handleRowClick({ item }) {
            this.$emit('on-trip-click', item.trip);
        },
        hasTripWarning(trip) {
            return trip?.subtractedTotalAmount !== 0;
        },
        hasDriverWarning(trip) {
            const driverNumberIsName = trip.driver?.name == trip.driver.driverNumber;
            return driverNumberIsName;
        },
        formatCurrency(value) {
            return round2d(value / 100).format();
        },
    },
};
</script>

<style lang="scss" scoped></style>
