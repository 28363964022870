var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BaseDashboardTable', {
    attrs: {
      "columns": _vm.columns,
      "items": _vm.sortedTrips,
      "sort": _vm.sortState,
      "empty-message": 'Keine Fahrten gefunden',
      "scrollContainer": "Trips",
      "isClickable": ""
    },
    on: {
      "sort-changed": _vm.handleSortChanged,
      "row-click": _vm.handleRowClick
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('h3', [_vm._v("Fahrten")])];
      },
      proxy: true
    }, {
      key: "column-umsatz",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', {
          style: {
            color: _vm.hasTripWarning(item.trip) ? 'red' : 'inherit'
          }
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.trip.finalTotalAmount)) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }