<template>
    <div class="Trips" :class="{ 'is-loading': isFetchingData }">
        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    :items="drivers"
                    :selected="selectedDriver"
                    placeholder="Fahrer"
                    @onItemSelect="handleDriverSelect"
                />
                <Dropdown
                    hasSearch
                    :items="cars"
                    :selected="selectedCar"
                    placeholder="Kennzeichen"
                    @onItemSelect="handleCarSelect"
                />
                <Datepicker
                    @onChange="handleDateChange"
                    :startDate="selectedFrom"
                    :endDate="selectedTo"
                />
                <Button size="small" @onClick="handleFetchTrips" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
                <PageNavigationToolbar
                    :selectedCar="selectedCar"
                    :selectedEmployee="selectedDriver"
                />
            </div>
            <div>
                <!-- <Download title="Download PDF" type="pdf" @onDownload="handleDownload" /> -->
                <Download title="Download CSV" type="csv" @onDownload="handleDownload" />
            </div>
        </Toolbar>
        <TripsTable :trips="trips" @onSumOfAllGaps="handleSumOfAllGaps" />
    </div>
</template>

<script>
import {
    endOfDay,
    parseISO,
    isValid,
    format,
    formatISO,
    startOfDay,
    startOfMonth,
    subDays,
} from 'date-fns';
import axios from 'axios';
import Button from '@/components/widgets/Button';
import Datepicker from '@/components/widgets/Datepicker';
import Download from '@/components/widgets/Download';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import TripsTable from './components/TripsTable';
import PageNavigationToolbar from '@/components/PageNavigationToolbar.vue';

import { distanceToString, getDuration, priceToEuroString, round2d } from '@/lib/helper';

export default {
    name: 'Trips',
    components: {
        Download,
        Button,
        Datepicker,
        Dropdown,
        Headline,
        Toolbar,
        TripsTable,
        PageNavigationToolbar,
    },
    data: () => ({
        isFetchingData: false,
        trips: [],
        shiftsWithoutRegistration: 0,
        drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
        cars: JSON.parse(localStorage.getItem('cars')),
        selectedCar: null,
        selectedDriver: null,
        selectedFrom: startOfMonth(new Date()),
        selectedTo: new Date(),
    }),
    computed: {},
    methods: {
        handleShowOnPage(path) {
            const { selectedFrom, selectedTo, selectedCar, selectedDriver } = this;
            const params = {
                from: format(selectedFrom, 'yyyy-MM-dd'),
                to: format(selectedTo, 'yyyy-MM-dd'),
                car: selectedCar?.value,
                driver: selectedDriver?.value,
            };
            this.$router.push({ name: path, query: params });
        },
        handleSumOfAllGaps(sum) {
            this.shiftsWithoutRegistration = distanceToString(sum);
        },
        handleDateChange(daterange) {
            this.selectedFrom = daterange.from;
            this.selectedTo = daterange.to;
        },
        handleCarSelect({ item }) {
            this.selectedCar = item;
        },
        handleDriverSelect({ item }) {
            this.selectedDriver = item;
        },
        handleDownload(type) {
            if (type === 'pdf') {
            } else if (type === 'csv') {
                const driversMap = Object.fromEntries(this.drivers.map(d => [d.id, d]));

                const csv = this.trips.map(r => {
                    return {
                        Fahrer: driversMap[r.driverNumber]?.value ?? r.driverNumber,
                        Schichtnummer: r.shiftSequenceNumber,
                        Fahrtnummer: r.sequenceNumber,
                        Kennzeichen: r.licenseNumber,
                        Abfahrt: format(new Date(r.startAt), 'dd.MM.yy HH:mm'),
                        Ankunft: format(new Date(r.endAt), 'dd.MM.yy HH:mm'),
                        Dauer: getDuration(r.duration),
                        'Leer-KM': distanceToString(r.totalDistance - r.hiredDistance, '', ''),
                        'Besetzt-KM': distanceToString(r.hiredDistance, '', ''),
                        'Gesamt-KM': distanceToString(r.totalDistance, '', ''),
                        Umsatz: round2d(r.finalTotalAmount / 100).format(),
                    };
                });
                const csvString = `${Object.keys(csv[0]).join(';')}\n${csv
                    .map(r => Object.values(r).join(';'))
                    .join('\n')}`;
                const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
                const link = document.createElement('a');
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);

                const filename = `Einzelfahrten_${this.selectedCar?.name || ''}_${format(
                    this.selectedFrom,
                    'dd.MM.yyyy',
                )}_${format(this.selectedTo, 'dd.MM.yyyy')}.csv`;
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        async handleFetchTrips() {
            this.isFetchingData = true;
            const queryData = {
                driverNumber: this.selectedDriver ? this.selectedDriver.id : null,
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };
            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/trips`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });
                this.$emit('onFinishLoading');
                this.trips = result.data;
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
    },

    mounted() {
        const query = this.$route.query;
        const { from, to, car, driver } = query;
        if (from && isValid(parseISO(from))) {
            this.selectedFrom = parseISO(from); // Only set if the parsed date is valid
        }
        if (to && isValid(parseISO(to))) {
            this.selectedTo = parseISO(to); // Only set if the parsed date is valid
        }
        if (car) {
            this.selectedCar = this.cars.find(c => c.id == car) || null; // Fallback to null if not found
        }
        if (driver) {
            this.selectedDriver = this.drivers.find(e => e.id == driver) || null; // Fallback to null if not found
        }
        this.handleFetchTrips();
    },
};
</script>

<style lang="scss">
.Trips {
    @extend %contentWrapper;
    @extend %page;
}
</style>
